.prev-galaxy {
  transform: translateX(40rem) translateY(-5rem) scale(0.75) perspective(2000px)
    rotateY(20deg);
}

.next-galaxy {
  transform: translateX(-40rem) translateY(-5rem) scale(0.75)
    perspective(2000px) rotateY(-20deg);
}

.current-galaxy {
  transform: scale(1);
}

.prev-galaxy,
.next-galaxy {
  opacity: 50%;
  z-index: -10;
}

.wrap {
  margin: 0 auto;
  width: 450px;
}
.slider-container {
  width: 100%;
}

#range {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  top: 100px;
  opacity: 0;
}

#range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 50px;
  background: #ccc;
  cursor: pointer;
}

#range::-moz-range-thumb {
  width: 40px;
  height: 50px;
  background: #ccc;
  cursor: pointer;
}

@media screen and (max-width: 1440px) {
  .prev-galaxy {
    transform: translateX(30rem) translateY(-5rem) scale(0.75)
      perspective(2000px) rotateY(20deg);
  }

  .next-galaxy {
    transform: translateX(-30rem) translateY(-5rem) scale(0.75)
      perspective(2000px) rotateY(-20deg);
  }
}

@media screen and (max-width: 1024px) {
  .prev-galaxy {
    display: hidden;
  }

  .next-galaxy {
    display: hidden;
  }
  .current-galaxy canvas {
    width: 100%;
  }
  .canvas-container {
    width: 100%;
    height: 60%;
  }
}

@media screen and (max-width: 425px) {
  .canvas-container {
    width: 100%;
    height: 80%;
  }
}

@media screen and (max-width: 320px) {
  .canvas-container {
    width: 90%;
    height: 65%;
  }
}
