.parent {
  position: relative;
  width: fit-content;
  height: fit-content;
  color: white;
}

.text-block {
  position: absolute;
  width: fit-content;
  height: fit-content;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  padding: 0.25rem 2rem;
  border: 1px solid white;
  font-size: 1.5rem;
  color: white;
}
