.video-thumbnail:hover {
  transform: scale(0.95);
}

.video-thumbnail {
  transition: transform 300ms ease;
}

.video-caption {
  margin-top: 1rem;
  text-decoration: underline;
}

.video-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1rem;
}

.video-wrapper > a {
  padding-right: 1rem;
  margin-bottom: 1rem;
}

.product-wrapper {
  text-align: left;
}

.mute-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  height: 1rem;
  width: 1rem;
  z-index: 999;
}

.content-box {
  box-shadow: 10px 10px 0px white;
  border: 1px solid white;
}

.scaled-header-products {
  position: relative;
}

.product-content-panel {
  overflow: auto;
}

.product-content-panel::-webkit-scrollbar {
  display: block;
  width: 5px;
  transition: 300ms ease;
}

.product-content-panel::-webkit-scrollbar-track {
  display: none;
}

.product-content-panel::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 5px;
}

.product-content-panel::-webkit-scrollbar-corner {
  display: none;
}

.loader {
  width: 18px;
  height: 18px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

.content-fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.content-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1000ms ease-in, transform 1000ms ease-out;
}

.content-fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.content-fade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1000ms ease-in, transform 1000ms ease-out;
}
