@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");

* {
  font-family: "Montserrat", sans-serif !important;
}

.wrapper {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.button {
  width: fit-content;
  height: fit-content;
  border-width: 1.5px;
  border-radius: 8px;
  background: black;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  padding: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  overflow: hidden;
  transition: all 0.5s;
  /* box-shadow: 5px 3px 3px rgba(255, 255, 255, 0.5); */
}

.button:hover {
  transition: all 0.5s;
  background: white;
  color: black;
  transform: scale(1.2);
}